import { USER_ROLE_ID } from "./environment";

export const EQUIPMENT_STATUS = {
  JDE_E1_ORIGINAL: "JDE E1 original",
  IN_SCOPE_OF_DATA_SCRAPPING: "In scope of data scrapping",
  IN_SCOPE_OF_DATA_SCRAPING: "In scope of data scraping", // issue from BE, status comes with typo
  DATA_SCRAPPING_COMPLETED: "Data scrapping completed",
  DATA_SCRAPING_COMPLETED: "Data scraping completed", // issue from BE, status comes with typo
  IN_SCOPE_FOR_ENRICHMENT: "In scope for enrichment",
  ENRICHMENT_COMPLETED: "Enrichment completed",
  UNDER_DISCIPLINE_SME_REVIEW: "Under discipline SME review",
  DISCIPLINE_SME_REVIEW_COMPLETED: "Discipline SME review completed",
  ACD_COMPLETED: "ACD completed",
  UNCONFIRMED: "Unconfirmed",
  READY_FOR_JDE_UPDATE: "Ready for JDE update",
  UPDATED_BY_DC: "Updated by DS",
  UPDATED_BY_LTI: "Updated by LTI",
  RETURN_TO_DC: "Return to DC",
  REVIEW_BY_LTI: "Review by LTI",
  REVIEW_BY_DC: "Review by DC",
  READY_FOR_QA_QC: "Ready for QA/QC",
  COMPLETED_BY_LTI: "Completed by LTI",
  REVIEW_BY_ARCHIVE: "Review by Archive",
  REVIEW_BY_FV: "Review by FV",
};

export const EQUIPMENT_CRITICALITY = {
  LOW: "Low",
  NORMAL: "Normal",
  CRITICAL: "Critical",
  NOT_APPLICABLE: "Not applicable",
};

export const FILTER_SOURCE = {
  WORK_WITH_DATA: "WORK_WITH_DATA",
  MATERIALS_MANAGEMENT: "MATERIALS_MANAGEMENT",
};

export const ENTER_KEY = "Enter";

export const FILE_FORMAT = {
  CSV: "csv",
  EXCEL: "xlsx",
};

export const FILTER_FREQUENCY = 3;

export const STATUS_TYPE = {
  ATTRIBUTE_STATUS: "EquipmentCharacteristicStatus",
  EQUIPMENT_STATUS: "EquipmentStatus",
};

export const ALLOWED_ACTIONS_BY_USER_ROLE = {
  [USER_ROLE_ID.RestrictedUser]: {
    wwd: false, // Work with data tab
    wm: false, // Walkdown management tab
    monitor: false, // Monitor tab
    mm: false, // Materials Management tab
    all: false, // All actions enabled or disabled
  },
  [USER_ROLE_ID.Admin]: {
    wwd: true,
    wm: true,
    monitor: true,
    mm: true,
    all: true,
  },
  [USER_ROLE_ID.DataTeamUser]: {
    wwd: true,
    wm: true,
    monitor: true,
    mm: true,
    all: true,
  },
  [USER_ROLE_ID.EnrichmentUser]: {
    wwd: true,
    wm: true,
    monitor: true,
    mm: true,
    all: true,
  },
  [USER_ROLE_ID.MMSystemAnalyst]: {
    wwd: true,
    wm: true,
    monitor: true,
    mm: true,
    all: true,
  },
  [USER_ROLE_ID.SMEUser]: {
    wwd: true,
    wm: true,
    monitor: true,
    mm: true,
    all: true,
  },
  [USER_ROLE_ID.FieldEngineers]: {
    wwd: false,
    wm: false,
    monitor: false,
    mm: false,
    all: false,
  },
  [USER_ROLE_ID.WalkdownCoordinator]: {
    wwd: false,
    wm: true,
    monitor: true,
    mm: false,
    all: false,
  },
};
