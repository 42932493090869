import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, ButtonGroup } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { isSchedulesListUpdated } from "../../store/slices/walkdown-management/schedulesSlice";
import { isWalkdownUsersListUpdated } from "../../store/slices/walkdown-management/walkdownUsersSlice";
import { allowedUserActions } from "../../store/slices/global/userSlice";
import { useWalkdownManagement } from "../../hooks/useWalkdownManagement";

import { WalkdownManagementSchedulesTable } from "./WalkdownManagementSchedulesTable";
import { WalkdownManagementUsersTable } from "./WalkdownManagementUsersTable";
import { HeaderWithActions } from "../common/HeaderWithActions";

export const WalkdownManagement = () => {
  const allowedActions = useSelector(allowedUserActions);
  const isUserActionEnabled = allowedActions.all || allowedActions.wm;
  const isSchedulesUpdated = useSelector(isSchedulesListUpdated);
  const isUsersUpdated = useSelector(isWalkdownUsersListUpdated);
  const [activeButton, setActiveButton] = useState("schedules");
  const [openNewScheduleModal, setOpenNewScheduleModal] = useState(false);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);

  const [isSchedulesLoading, setIsSchedulesLoading] = useState(true);
  const [schedules, setSchedules] = useState([]);

  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [walkdownUsers, setWalkdownUsers] = useState([]);

  const { getWalkdownSchedules, getWalkdownUsers } = useWalkdownManagement();

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsSchedulesLoading(true);
      getWalkdownSchedules()
        .then((response) => {
          if (isMounted && response) {
            setSchedules(response);
            setIsSchedulesLoading(false);
          }
        })
        .finally(() => {
          setIsSchedulesLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getWalkdownSchedules, isSchedulesUpdated]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      try {
        setIsUsersLoading(true);
        getWalkdownUsers().then((response) => {
          if (isMounted && response) {
            const users = response.filter((user) => user.userPk !== 1);
            setWalkdownUsers(users);
            setIsUsersLoading(false);
          }
        });
      } finally {
        setIsUsersLoading(false);
      }
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getWalkdownUsers, isUsersUpdated]);

  const handleTabClick = (btn) => {
    setActiveButton(btn);
  };

  const handleAddNewSchedule = () => {
    setOpenNewScheduleModal(true);
  };

  const closeNewScheduleModal = () => {
    setOpenNewScheduleModal(false);
  };

  const handleAddNewUser = () => {
    setOpenAddUserModal(true);
  };

  const closeAddUserModal = () => {
    setOpenAddUserModal(false);
  };

  return (
    <div className="flex flex-col gap-6 w-full">
      <HeaderWithActions title="Walkdown Management" />
      <div className="flex items-center justify-between">
        <ButtonGroup variant="outlined" aria-label="Power Bi Dashboards">
          <Button
            sx={{
              width: "130px",
              backgroundColor: activeButton === "schedules" ? "#0066B214" : "",
            }}
            onClick={() => handleTabClick("schedules")}
            data-testid="schedules-btn"
          >
            Schedules
          </Button>
          <Button
            sx={{
              width: "130px",
              backgroundColor: activeButton === "users" ? "#0066B214" : "",
            }}
            onClick={() => handleTabClick("users")}
            data-testid="users-btn"
          >
            Users
          </Button>
        </ButtonGroup>
        {isUserActionEnabled && (
          <>
            {activeButton === "schedules" && (
              <Button
                sx={{ fontSize: "14px" }}
                startIcon={<AddIcon />}
                onClick={handleAddNewSchedule}
                data-testid="add-new-schedule-btn"
              >
                Add new schedule
              </Button>
            )}
            {activeButton === "users" && (
              <Button
                sx={{ fontSize: "14px" }}
                startIcon={<AddIcon />}
                onClick={handleAddNewUser}
                data-testid="add-user-btn"
              >
                Add user
              </Button>
            )}
          </>
        )}
      </div>
      <div className="w=-full">
        {activeButton === "schedules" && (
          <WalkdownManagementSchedulesTable
            rows={schedules}
            loading={isSchedulesLoading}
            openNewScheduleModal={openNewScheduleModal}
            closeNewScheduleModal={closeNewScheduleModal}
            walkdownUsers={walkdownUsers}
            isUserActionEnabled={isUserActionEnabled}
          />
        )}
        {activeButton === "users" && (
          <WalkdownManagementUsersTable
            rows={walkdownUsers}
            loading={isUsersLoading}
            openAddUserModal={openAddUserModal}
            closeAddUserModal={closeAddUserModal}
            isUserActionEnabled={isUserActionEnabled}
          />
        )}
      </div>
    </div>
  );
};
