import { createSlice } from "@reduxjs/toolkit";

const characteristicsSlice = createSlice({
  name: "characteristics",
  initialState: {
    isCharacteristicsUpdated: null,
    isSpecificCharsUpdated: null,
    isWalkdownPicturesUpdated: null,
    selectedEquipmentTagId: null,
  },
  reducers: {
    setIsCharacteristicsUpdated: (state) => {
      state.isCharacteristicsUpdated = Date.now().toString();
    },
    setIsSpecificCharsUpdated: (state) => {
      state.isSpecificCharsUpdated = Date.now().toString();
    },
    setIsWalkdownPicturesUpdated: (state) => {
      state.isWalkdownPicturesUpdated = Date.now().toString();
    },
    setSelectedEquipmentTagId: (state, action) => {
      localStorage.setItem(
        "selectedEquipmentTagId",
        JSON.stringify(action.payload)
      );
      state.selectedEquipmentTagId = action.payload;
    },
  },
});

export const {
  setIsCharacteristicsUpdated,
  setIsSpecificCharsUpdated,
  setIsWalkdownPicturesUpdated,
  setSelectedEquipmentTagId,
} = characteristicsSlice.actions;
export const isCharacteristicsUpdated = (state) =>
  state.characteristics.isCharacteristicsUpdated;
export const isSpecificCharsUpdated = (state) =>
  state.characteristics.isSpecificCharsUpdated;
export const isWalkdownPicturesUpdated = (state) =>
  state.characteristics.isWalkdownPicturesUpdated;
export const selectedEquipmentTagId = (state) => {
  const selectedEquipmentTagId = localStorage.getItem("selectedEquipmentTagId");
  if (selectedEquipmentTagId) {
    return JSON.parse(selectedEquipmentTagId);
  } else {
    return state.characteristics.selectedEquipmentTagId;
  }
};

export default characteristicsSlice.reducer;
